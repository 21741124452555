<template>
	<div style="position:relative;width:100%;height:100%">
		<div id="container"></div>
		<div class="ser_view">
			<!-- <div class="item">
				<el-cascader v-model="cities_choosed" placeholder="省市区选择" :options="cities_arr" @change="cities_choose_change" filterable style="width:100%"></el-cascader>
			</div> -->
			<div class="item">
				<input class="text" v-model="addr_choose_input" @input="ser" placeholder="请输入地址" style="width: 314px;">
				<div class="btn" @click="ser">搜索</div>
			</div>
			<div class="item" v-if="addr_choose_rel">
				<img class="img" v-if="addr_choose_img" :src="addr_choose_img">
				<div class="text" style="width: 200px;">{{addr_choose_rel}}</div>
				<div class="btn btn_blue" @click="choose">确定</div>
			</div>
		</div>
		
		<div class="addr_choose_list" v-if="addr_choose_list_is_show">
			<div class="addr_choose_ser_item" v-for="(item,index) in addr_choose_list" :key="index" @click="rel_to_map(index)">
				<div class="addr_choose_ser_item_name">{{item.name}}</div>
				<div class="addr_choose_ser_item_addr">{{item.district}}{{item.address}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			old_data:Object,
		},
		data() {
			return {
				addr_choose_input:'',
				addr_choose_list_is_show:false,
				addr_choose_list:[],
				addr_choose_rel:'',
				addr_choose_obj:{},
				addr_choose_img:'',
				map_obj:{},
				ser_obj:{},

				//三级联动相关
				// cities_arr:[],
				// cities_choosed:[],
			};
		},
		created() {

			this.$amap.load({
				plugins:[
					'AMap.AutoComplete',
					'AMap.PlaceSearch',
					'AMap.DistrictSearch'
				]
			}).then((AMap)=>{

				//初始化
				this.init(AMap);
			})
		},
		methods: {

			//提交
			choose(){
				this.$emit('choosed',{
					prov_name:this.addr_choose_obj.pname,
					prov_code:this.addr_choose_obj.pcode,
					city_name:this.addr_choose_obj.cityname,
					city_code:this.addr_choose_obj.citycode,
					county_name:this.addr_choose_obj.adname,
					county_code:this.addr_choose_obj.adcode,
					addr_info:this.addr_choose_obj.name,
					lng:this.addr_choose_obj.location.lng,
					lat:this.addr_choose_obj.location.lat,
				})
			},

			//搜索
			rel_to_map(index){
				let choosed={...this.addr_choose_list[index]}
				this.addr_choose_input=choosed.name
				this.ser_obj.setCity(choosed.adcode);
				this.ser_obj.search(choosed.name)
				this.addr_choose_rel=''
			},
			// cities_choose_change(){

			// 	//设置地图中心点
			// 	this.map.setCenter(this.CodeToCityItem[this.cities_choosed[2]].center);

			// 	//清空搜索框
			// 	this.addr_choose_input=''
			// 	this.addr_choose_rel='';
			// 	this.addr_choose_list=[];
			// 	this.addr_choose_list_is_show=false;
			// },
			ser(){
				this.autocomplete.search(this.addr_choose_input,(status, rel)=>{
					if(status=='complete'){
						if(rel.tips.length>0){
							this.addr_choose_list=rel.tips
							this.addr_choose_list_is_show=true;
						}
					}
				})
			},
			
			//初始化
			init(AMap){

				//地图初始化
				this.map = new AMap.Map("container",{

					resizeEnable: true,

					//初始化地图级别
					zoom:12,
				});

				//自动完成初始化
				this.autocomplete = new AMap.AutoComplete()

				//搜索初始化
				this.ser_obj = new AMap.PlaceSearch({
					map:this.map,
					citylimit: true,
					autoFitView: true 
				})

				//搜索结果添加事件
				AMap.Event.addListener(this.ser_obj,'markerClick',(event)=>{
					this.addr_choose_list_is_show=false;
					this.addr_choose_rel=event.data.pname+'/'+event.data.cityname+'/'+event.data.adname+'/'+event.data.address
					this.addr_choose_obj=event.data
					if(event.data.photos.length>0){
						this.addr_choose_img=event.data.photos[0].url
					}
				})

				//处理传入的默认值
				this.addr_choose_input=`${this.old_data.prov_name}${this.old_data.city_name}${this.old_data.county_name}${this.old_data.addr_info}`
				this.ser()
			},
		},
	};
</script>
<style scoped>

	#container{
		width:100%;
		height:100%;
	}

	.ser_view{
		position: absolute;
		left: 20px;
		top: 20px;
		border-radius: 4px;
		box-shadow: 2px 2px 5px #777;
		background-color: #fff;
	}
	.item{
		display:flex;
		justify-content: space-between;
		margin: 10px 15px;
	}
	.text{
		line-height: 36px;
		font-size: 15px;
		padding: 0 10px;
		color: #666;
		border: 1px solid #ccc;
	}
	.city_select{
		font-size: 14px;
		padding: 0 3px;
		color: #666;
		height: 38px;
		border: 1px solid #ccc;
	}
	.btn{
		margin-left: 7px;
		font-size: 14px;
		line-height: 38px;
		width: 60px;
		height: 38px;
		text-align: center;
		cursor: pointer;
		color:#fff;
		background-color: #67c23a;
	}
	.img{
		width:114px;
		height:114px;
	}
	.btn_blue{
		background-color: #409eff;
	}


	.addr_choose_list{
		position:absolute;
		width:433px;
		max-height: 500px;
		overflow-y:auto;
		top: 83px;
		left: 20px;
		box-shadow: 2px 2px 5px #777;
		background-color: #eee;
	}
	.addr_choose_ser_item{
		background-color: #fff;
		cursor:pointer;
		margin: 10px;
	}
	.addr_choose_ser_item:hover{
		background-color: #409eff;
		color:#fff;
	}
	.addr_choose_ser_item_name{
		padding: 10px;
		border-bottom: 1px solid #ccc;
	}
	.addr_choose_ser_item_addr{
		padding: 10px 10px 15px 10px;
		color: #ccc;
	}
	
</style>