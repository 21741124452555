 <template>
	<el-dialog title="添加物流园" top="1vh" width="800px" :visible.sync="is_show_in_page">
		<el-form label-width='80px'>
			<el-form-item label="园区名称">
				<el-input v-model="form.name" clearable></el-input>
			</el-form-item>
			<el-form-item label="联系人">
				<el-input v-model="form.link_man" clearable></el-input>
			</el-form-item>
			<el-form-item label="联系电话">
				<el-input v-model="form.link_tel" clearable></el-input>
			</el-form-item>
			<el-form-item label="代理地址">
				<el-input v-model="addr_map_choose.prov_city_county_full_text" disabled></el-input>
				<el-input v-model="addr_map_choose.old_data.addr_info" placeholder="详细地址(地图选点)">
					<el-button slot="append" @click="addr_map_open">地图</el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="备注">
				<el-input v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item >
				<el-button type="primary" @click="sub">提交</el-button>
			</el-form-item>
		</el-form>

		<!-- 物流园地址选择 -->
		<el-dialog 
			title="物流园地址选择" 
			top="1vh" width="90%" 
			append-to-body 
			custom-class="el-dialog-s" 
			:visible.sync="addr_map_choose.is_show"
		>
			<mapChoose 
				style="height:700px" 
				v-if="addr_map_choose.is_show" 
				:old_data="addr_map_choose.old_data" 
				@choosed="addr_map_choosed"
			></mapChoose>
		</el-dialog>

	</el-dialog>
</template>

<script>
	import mapChoose from '@/components/map/amap/choose.vue'
	export default {
		components:{
			mapChoose
		},
		props:{
			is_show:Number,
		},
		data() {
			return {
				
				//是否显示
				is_show_in_page:false,

				//表单
				form:{
					name:'',
					link_man:'',
					link_tel:'',
					mark:'',
				},

				//仓库地址地图选择
				addr_map_choose:{
					is_show:false,
					addr_full_text:'',
					prov_city_county_full_text:'',
					old_data:{
						prov_code:'',
						prov_name:'',
						city_code:'',
						city_name:'',
						county_code:'',
						county_name:'',
						addr_info:'',
						lat:'',
						lng:'',
					}
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;
					
					//初始化
					this.init();

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//初始化
			init(){
				this.form.name=''
				this.form.link_man=''
				this.form.link_tel=''
				this.form.mark=''
				this.addr_map_choose.addr_full_text=''
				this.addr_map_choose.prov_city_county_full_text=''
				this.addr_map_choose.old_data.prov_code=''
				this.addr_map_choose.old_data.prov_name=''
				this.addr_map_choose.old_data.city_code=''
				this.addr_map_choose.old_data.city_name=''
				this.addr_map_choose.old_data.county_code=''
				this.addr_map_choose.old_data.county_name=''
				this.addr_map_choose.old_data.addr_info=''
				this.addr_map_choose.old_data.lat=''
				this.addr_map_choose.old_data.lng=''
			},

			//地址选择相关
			addr_map_open(){
				this.addr_map_choose.is_show=true;
			},
			addr_map_choosed(rel){
				this.addr_map_choose.is_show=false;
				this.addr_map_choose.old_data.prov_name=rel.prov_name
				this.addr_map_choose.old_data.city_name=rel.city_name
				this.addr_map_choose.old_data.county_name=rel.county_name
				this.addr_map_choose.old_data.addr_info=rel.addr_info
				this.addr_map_choose.old_data.lat=rel.lat
				this.addr_map_choose.old_data.lng=rel.lng
				this.addr_map_choose.addr_full_text=`${rel.prov_name} / ${rel.city_name} / ${rel.county_name} / ${rel.addr_info}`
				this.addr_map_choose.prov_city_county_full_text=`${rel.prov_name} / ${rel.city_name} / ${rel.county_name}`
			},

			//提交
			sub(){

				//名称
				if(!this.form.name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"名称为空,请输入"
					});
					return;
				}

				//调用接口
				this.$my.net.req({
					data:{
						mod:'logistics',
						ctr:'park_add_by_admin',
						prov:this.addr_map_choose.old_data.prov_name,
						city:this.addr_map_choose.old_data.city_name,
						county:this.addr_map_choose.old_data.county_name,
						addr:this.addr_map_choose.old_data.addr_info,
						lat:this.addr_map_choose.old_data.lat,
						lng:this.addr_map_choose.old_data.lng,
						...this.form,
					},
					callback:(data)=>{

						//报告结果
						this.$my.other.msg({
							type:'success',
							str:"添加成功"
						});

						//关闭弹出层
						this.is_show_in_page=false;

						//通知
						this.$emit("added");
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>

	.el-dialog-s{
		z-index: 11;
		height:calc(100% - 2vh) !important;
	}
</style>